import { createStore } from "vuex";
import axiosInstance from "../assets/js/axiosInstance.js";

export default createStore({
  modules: {},
  state: {
    session: {},
    prompt: {},
    prompts: [],
    responses: [],
    response: {},
    totalResponses: 0,
    newResponses: 0,
    directUploadUrl: "",
    cameraId: "",
    microphoneId: "",
    token: "",
  },
  mutations: {
    setSession(state, session) {
      state.session = session;
    },
    setToken(state, data) {
      localStorage.setItem("authToken", data.token);
      state.token = data.token;
    },
    setPrompts(state, prompts) {
      state.prompts = prompts;
    },
    setResponses(state, data) {
      state.responses = data.responses;
      state.totalResponses = data.total_count;
      state.newResponses = data.new_count;
      state.prompts = data.prompts;
    },
    setNewResponses(state, n) {
      state.newResponses = n;
    },
    setDirectUploadUrl(state, directUploadUrl) {
      state.directUploadUrl = directUploadUrl;
    },
    setPrompt(state, promptData) {
      state.prompt = promptData;
    },
    setResponse(state, responseData) {
      state.response = responseData;
    },
    setCamera(state, deviceId) {
      localStorage.setItem("cameraId", deviceId);
      state.cameraId = deviceId;
    },
    setMicrophone(state, deviceId) {
      localStorage.setItem("microphoneId", deviceId);
      state.microphoneId = deviceId;
    },
  },
  getters: {
    newResponses(state) {
      return state.newResponses;
    },
    totalResponses(state) {
      return state.totalResponses;
    },
    session(state) {
      return state.session;
    },
    prompts(state) {
      return state.prompts;
    },
    responses(state) {
      return state.responses;
    },
    uploadUrl(state) {
      return state.directUploadUrl;
    },
    prompt(state) {
      return state.prompt;
    },
    response(state) {
      return state.response;
    },
    cameraId(state) {
      if (state.cameraId.length > 0) {
        return state.cameraId;
      } else {
        return localStorage.getItem("cameraId");
      }
    },
    microphoneId(state) {
      if (state.microphoneId.length > 0) {
        return state.microphoneId;
      } else {
        return localStorage.getItem("microphoneId");
      }
    },
  },
  actions: {
    validateToken({ commit }) {
      return axiosInstance
        .get("/session")
        .then((response) => {
          commit("setSession", response.data);
          return response;
        })
        .catch((error) => error.response);
    },
    login({ commit }, data) {
      return axiosInstance
        .post("/sessions", data)
        .then((response) => {
          commit("setToken", response.data);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("setSession", {});
        commit("setToken", "");
        resolve();
      });
    },
    getPrompt({ commit }, hashid) {
      return axiosInstance
        .get(`/prompts/${hashid}`)
        .then((response) => {
          commit("setPrompt", response.data);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    getPrompts({ commit }) {
      return axiosInstance
        .get("/prompts")
        .then((response) => {
          commit("setPrompts", response.data);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    getResponses({ commit }, params) {
      return axiosInstance
        .get("/responses", {
          params: params,
        })
        .then((response) => {
          commit("setResponses", response.data);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    getResponse({ commit }, hashid) {
      return axiosInstance
        .get(`/responses/${hashid}`)
        .then((response) => {
          commit("setResponse", response.data);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    destroyPrompt({}, id) {
      return axiosInstance
        .delete(`/prompts/${id}`)
        .then((response) => response)
        .catch((error) => error.response);
    },
    savePrompt({ commit }, data) {
      return axiosInstance({
        url: data.id ? `/prompts/${data.id}` : `/prompts`,
        method: data.id ? "PATCH" : "POST",
        data: { ...data },
      })
        .then((response) => {
          // commit("")
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    createResponse({}, data) {
      return axiosInstance
        .post("/responses", data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    viewResponse({}, responseId) {
      return axiosInstance
        .post(`/responses/${responseId}/viewed`)
        .then((response) => response)
        .catch((error) => error.response);
    },
    destroyResponse({}, hashid) {
      return axiosInstance
        .delete(`/responses/${hashid}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
    getDirectUploadUrl({ commit }) {
      return axiosInstance
        .get("/responses/direct_upload_url")
        .then((response) => {
          commit("setDirectUploadUrl", response.data.upload_url);
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    },
  },
});
