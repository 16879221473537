import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Font Awesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGear,
  faChevronRight,
  faChevronLeft,
  faInbox,
  faTrash,
  faBars,
  faArrowsRotate,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faGear,
  faArrowsRotate,
  faInbox,
  faChevronRight,
  faChevronLeft,
  faBars,
  faTrash,
  faMicrophone
);

import "./assets/css/main.scss";
import "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
  createApp(App)
    .use(router)
    .use(store)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");
});
