import axios from "axios";

const axiosInstance = axios.create({
  timeout: 15000, // ms
  headers: {
    common: {
      "content-type": "application/json",
    },
  },
});

axiosInstance.defaults.baseURL = `${
  process.env.API_ENV === "production" ? "https" : "http"
}://${process.env.ROUTE_HOST}/api`;

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
