import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/app",
    redirect: { name: "login" },
  },
  {
    path: "/app",
    name: "home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/Login.vue"),
      },
      {
        path: "invalid",
        name: "invalid",
        component: () => import("../views/InvalidPrompt.vue"),
      },
      {
        path: "record/:entryId/create",
        name: "record",
        props: true,
        component: () => import("../views/Record.vue"),
        beforeEnter: (to, from, next) => {
          store.dispatch("getPrompt", to.params.entryId).then((response) => {
            if (response.status < 300) {
              next();
            } else {
              next({ name: "invalid" });
            }
          });
        },
      },
      {
        path: "record/v2/:entryId/create",
        name: "recordV2",
        props: true,
        component: () => import("../views/Recorder.vue"),
        beforeEnter: (to, from, next) => {
          store.dispatch("getPrompt", to.params.entryId).then((response) => {
            if (response.status < 300) {
              next();
            } else {
              next({ name: "invalid" });
            }
          });
        },
      },
      {
        path: "record/:entryId/success",
        name: "success",
        props: true,
        component: () => import("../views/Success.vue"),
      },
      {
        path: "admin",
        name: "admin",
        component: () => import("../views/Admin.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "responses",
            name: "responses",
            component: () => import("../components/admin/Inbox.vue"),
            beforeEnter: (to, from, next) => {
              store.dispatch("getResponses", to.query).then((response) => {
                if (response.status < 300) {
                  next();
                } else {
                  next(false);
                }
              });
            },
          },
          {
            path: "responses/:hashid/watch",
            name: "watch",
            props: true,
            component: () => import("../components/admin/Watch.vue"),
            beforeEnter: (to, from, next) => {
              store
                .dispatch("getResponse", to.params.hashid)
                .then((response) => {
                  if (response.status < 300) {
                    next();
                  } else {
                    next(false);
                  }
                });
            },
          },
          {
            path: "prompts",
            name: "prompts",
            component: () => import("../components/admin/PromptList.vue"),
            beforeEnter: (to, from, next) => {
              store.dispatch("getPrompts").then((response) => {
                if (response.status < 300) {
                  next();
                } else {
                  next(false);
                }
              });
            },
          },
          {
            path: "prompts/:hashid/edit",
            name: "promptForm",
            props: true,
            component: () => import("../components/admin/PromptForm.vue"),
            beforeEnter: (to, from, next) => {
              store.dispatch("getPrompt", to.params.hashid).then((response) => {
                if (response.status < 300) {
                  next();
                } else {
                  next(false);
                }
              });
            },
          },
          {
            path: "prompts/new",
            name: "promptNew",
            props: true,
            component: () => import("../components/admin/PromptForm.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.API_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch("validateToken").then((response) => {
      if (response.status < 300) {
        next();
      } else {
        next({ name: "login" });
      }
    });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
