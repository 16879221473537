<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
#app {
  /* min-height: 100vh; */
}
</style>
